<template>
    <div class="notification">
        <div class="notification__container">
            <div v-show="type == 'warning'" class="notification__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/></svg>
            </div>
            <div class="notification__content notification-content">
                <div class="notification-content__name">{{ name }}</div>
                <div class="notification-content__description">{{ content }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Notification',
        props: {
            name: String,
            content: String,
            type: String
        }
    }
</script>

<style lang="scss">
    .notification {
        position: fixed;
        left: 0;
        top: 0;
        box-sizing: border-box;
        z-index: 1000;
        width: 100%;
        padding: 5px;
        &__container {
            max-width: 420px;
            margin: 0 auto;
            padding: 7px 10px;
            border-radius: 6px;
            box-shadow: 0 3px 15px 1px rgba(0,0,0,.25);
            background: #fff;
            display: flex;
            align-items: center;
        }
        &__icon {
            display: flex;
            align-items: center;
            padding-right: 10px;
            svg {
                width: 40px;
            }
        }
        &-content {
            &__name {
                font-size: 18px;
                font-weight: bold;
            }
            &__description {
                font-size: 14px;
                font-width: regular;
            }
        }
    }
</style>
<template>
    <div v-if="card.length !== 0" class="s-items__item si-item">
        <img @click="$router.push('/b/'+card.slug)" :src="card.img_url" class="si-item__img">
        <div @click="itemDelete" class="si-item__close">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.532 2.72049L10.7525 8.5L16.532 14.2795C17.156 14.9035 17.156 15.908 16.532 16.532C15.908 17.156 14.9035 17.156 14.2795 16.532L8.5 10.7525L2.72049 16.532C2.09651 17.156 1.09196 17.156 0.467981 16.532C-0.155994 15.908 -0.155994 14.9035 0.467981 14.2795L6.24749 8.5L0.467981 2.72049C-0.155994 2.09651 -0.155994 1.09196 0.467981 0.467981C1.09196 -0.155994 2.09651 -0.155994 2.72049 0.467981L8.5 6.24749L14.2795 0.467981C14.9035 -0.155994 15.908 -0.155994 16.532 0.467981C17.1516 1.09196 17.1516 2.10094 16.532 2.72049Z" fill="#262626"/>
            </svg>
        </div>
        <div class="si-item-content">
            <div class="si-item-header">
                <div class="si-item__name">{{card.title}}</div>
                <div class="si-item__author">{{card.authors.join(', ')}}</div>
            </div>
            <div class="si-item-footer">
                <div class="si-item-price">
                    <div v-show="card.cost_with_discount != null" class="si-item-price__nosale">{{card.cost_with_discount }} ₽</div>
                    <div class="si-item-price__sale">{{card.cost}} ₽</div>
                </div>
                <div class="si-item-amount">
                    <button @click="itemMinus" :class="{'si-item-amount__minus--block': amount === 1}" class="si-item-amount__minus"></button>
                    <div class="si-item-amount__current">{{amount}}</div>
                    <button @click="itemPlus" class="si-item-amount__plus"></button>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="s-items__item si-item si-item--loading">
        <div class="si-item__img"></div>
        <div class="si-item__close"></div>
        <div class="si-item-content">
            <div class="si-item-header">
                <div class="si-item__name"></div>
                <div class="si-item__author"></div>
            </div>
            <div class="si-item-footer">
                <div class="si-item-price">
                </div>
                <div class="si-item-amount">

                </div>
            </div>
        </div>
        {{this.loadBook()}}
    </div>
</template>

<script>
export default {
    name: 'orderItemComponent',
    data: function() {
        return {
            amount: 1,
            card: [],
            result: true
        }
    },
    props: [
        'id',
        'count'
    ],
    methods: {
        itemPlus() {
            this.amount++
            this.updateCount()
            this.updateCost(this.card.cost, this.card.cost_with_discount === undefined ? this.card.cost : this.card.cost_with_discount)
        },
        itemMinus() {
            if(this.amount !== 1) {
                this.amount--
                this.updateCount()
                this.updateCost(-this.card.cost, this.card.cost_with_discount === undefined ? -this.card.cost : -this.card.cost_with_discount)
            }
        },
        itemDelete() {
            this.updateCost(-this.card.cost * this.amount, this.card.cost_with_discount === undefined ? -this.card.cost * this.amount : -this.card.cost_with_discount * this.amount)
            this.$store.dispatch('removeCart', this.card.id)
        },
        updateCost(discount, original) {
            this.$emit('updateCost', discount, original)
        },
        async loadBook() {
            let response = await fetch(this.$store.state.config.backend+'/books/'+this.id, {
                method: 'GET'
            })
            if(response.ok) {
                let data = await response.json()
                this.card = data
                if(this.card.in_stock !== true) {
                    this.itemDelete()
                }
                for(let i = 0; i < this.count; i++) {
                    this.updateCost(this.card.cost, this.card.cost_with_discount === undefined ? this.card.cost : this.card.cost_with_discount)
                }
            } else {
                this.result = false
            }

        },
        updateCount() {
            this.$store.dispatch('editCart', [this.card.id, this.amount])
        }
    },
    created() {
        this.amount = this.count
    },
    watch: {
        'this.result'() {
            if(this.result === false) {
                this.itemDelete()
            }
        }
    }
}
</script>

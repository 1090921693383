import VueRouter from "vue-router";
import Main from "../pages/Main.vue";
import Book from "../pages/Book.vue";
import Order from "../pages/Order.vue";
import OrderAfter from "../pages/OrderAfter.vue";
import Error from "../pages/Error.vue";

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      component: Error,
    },
    {
      path: "/",
      component: Main,
      children: [{ path: "#page:id" }],
    },
    {
      path: "/b/:id",
      component: Book,
    },
    {
      path: "/order",
      component: Order,
    },
    {
      path: "/order-after",
      component: OrderAfter,
    },
  ],
});

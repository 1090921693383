<template>
    <div  id="main-page">
        <div @click="searchDisable()" :class="{'shadow--active': search.status === true}" class="shadow"></div>
        <nav class="nav" :class="{'nav--active': search.status === true}">
            <div class="nav-container">
                <router-link tag="div" to="/" class="nav-header">
                    <img src="../assets/img/logo.png" class="nav__logo">
                    <div class="nav__title">{{ $store.state.config.name }}</div>
                </router-link>
                <div class="nav-elem">
                    <router-link tag="button" to="/order" class="nav__cart">
                        <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.9196 6.76348L32.6163 20.7469C32.2946 21.7427 31.45 22.4066 30.4446 22.4066H13.8348C12.8696 22.4066 11.9446 21.7842 11.6228 20.8714L5.26848 3.3195H1.6087C0.723913 3.3195 0 2.57261 0 1.65975C0 0.746888 0.723913 0 1.6087 0H6.39457C7.07826 0 7.68152 0.456431 7.92283 1.12033L14.4783 19.0871H29.7609L33.1793 7.88382H14.237C13.3522 7.88382 12.6283 7.13693 12.6283 6.22407C12.6283 5.3112 13.3522 4.56431 14.237 4.56431H35.3913C35.9141 4.56431 36.3967 4.85477 36.6783 5.26971C37 5.68465 37.0804 6.26556 36.9196 6.76348ZM14.6391 24.6058C13.9554 24.6058 13.2717 24.8963 12.7891 25.3942C12.3065 25.8921 12.025 26.5975 12.025 27.3029C12.025 28.0083 12.3065 28.7137 12.7891 29.2116C13.2717 29.7095 13.9554 30 14.6391 30C15.3228 30 16.0065 29.7095 16.4891 29.2116C16.9717 28.7137 17.2533 28.0083 17.2533 27.3029C17.2533 26.5975 16.9717 25.8921 16.4891 25.3942C16.0065 24.8963 15.3228 24.6058 14.6391 24.6058ZM29.0772 24.6058C28.3935 24.6058 27.7098 24.8963 27.2272 25.3942C26.7446 25.8921 26.463 26.5975 26.463 27.3029C26.463 28.0083 26.7446 28.7137 27.2272 29.2116C27.7098 29.7095 28.3935 30 29.0772 30C29.7609 30 30.4446 29.7095 30.9272 29.2116C31.4098 28.7137 31.6913 28.0083 31.6913 27.3029C31.6913 26.5975 31.4098 25.8921 30.9272 25.3942C30.4446 24.8963 29.7609 24.6058 29.0772 24.6058Z" fill="#262626"/>
                        </svg>
                        <div class="nav__cart--msg">{{getAllCart()}}</div>
                    </router-link>
                </div>
            </div>
            <div class="nav-search" :class="{'nav-search--active': this.search.static}">
                <div class="nav-search__bar">
                    <label for="search-bar">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6328 23.3432L19.9815 16.1984C19.7441 15.9611 19.7789 16.7384 19.4414 16.7384L19.6186 17.717C21.0742 15.8557 21.9391 13.5145 21.9391 10.9677C21.9391 4.90909 17.0292 0 10.9696 0C4.90993 0 0 4.90909 0 10.9677C0 17.0263 4.90993 21.9354 10.9696 21.9354C13.5168 21.9354 15.8584 21.0706 17.72 19.6153L17.2813 19.4381C17.2813 19.7756 16.5039 19.7408 16.7412 19.978L23.3472 26.6283C23.843 27.1239 24.6446 27.1239 25.135 26.6283L26.6275 25.136C27.1233 24.6404 27.1233 23.8389 26.6328 23.3432ZM10.9696 17.717C7.24096 17.717 4.21906 14.7009 4.21906 10.9677C4.21906 7.23972 7.23569 4.21834 10.9696 4.21834C14.6981 4.21834 17.72 7.23445 17.72 10.9677C17.72 14.6956 14.7034 17.717 10.9696 17.717Z" fill="#878788"/>
                        </svg>
                    </label>
                    <input @focus="searchEnable" autocomplete="off" v-model="search.query" id="search-bar" placeholder="Поиск..." type="text">
                    <button v-show="search.status === true" @click="searchDisable(); search.data = []; search.query = ''">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 35px; height: 35px;" viewBox="0 0 320 512"><path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" fill="#878788"/></svg>
                    </button>
                </div>
                <div v-show="search.request === true" class="nav-search__items" :class="{'nav-search__items--active': search.status === true}">
                    <h3 style="text-align: center">По вашему запросу ничего не найдено</h3>
                </div>
                <div v-if="search.data.length !== 0" :class="{'nav-search__items--active': search.status === true}" class="nav-search__items">

                    <div  v-for="item in search.data" :key="item.id" class="nav-search-items__item ns-item">
                        <div class="ns-item-info">
                            <img @click="$router.push('/b/'+item.slug)" :src="item.img_url" alt="" class="ns-item__img">
<!--                            <button @click="$store.dispatch('removeCart', item.id)" v-if="checkCart(item.id)"  class="ns-item__btn ns-item__btn&#45;&#45;active">В корзине</button>-->
<!--                            <button @click="$store.dispatch('addCart', [item.id, 1])" v-else  class="ns-item__btn">В корзину</button>-->
                        </div>
                        <div class="ns-item-content">
                            <div class="ns-item__name">{{ item.title }}</div>
                            <div class="ns-item__author">{{ item.authors[0] }}</div>
                            <div class="ns-item__price">
                                <!--                                <div v-show="item.cost_with_discount != null" class="ns-item__price&#45;&#45;promo">-42%</div>-->
                                <div class="ns-item__price--sale">{{ item.cost}} ₽</div>
                                <!--                                <div v-show="item.cost_with_discount != null" class="ns-item__price&#45;&#45;nosale">{{ item.cost_with_discount }} ₽</div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <header class="header">
            <div class="container">
                <div v-if="this.$store.getters.getSlides.length !== 0" class="header-promo">
                    <div class="header-promo-content">
                        <div v-for="(item, key) in this.$store.getters.getSlides" :key="key" :class="{'header-promo-content__item--active': slider.activeSlide == key}" class="header-promo-content__item">
                            <div>
                                <div class="header-promo__title">{{ item.name }}</div>
                                <div class="header-promo__desc"> {{ item.desc }}</div>
                            </div>
                            <div class="header-promo__img">
                              <img :src="item.img_url" alt="">
                            </div>
                        </div>

                    </div>
                    <div v-show="$store.getters.getSlides.length > 1" class="header-promo-dots">
                        <div v-for="item in this.$store.getters.getSlides.length" :key="item" :class="{'header-promo__dot--active': slider.activeSlide == item - 1}" class="header-promo__dot"></div>
                    </div>
                </div>
                <div v-else-if="$store.state.slidesStatus === true" class="header-promo header-promo--loading">
                    <div class="header-promo-content header-promo-content--loading"></div>
                    <div class="header-promo-dots header-promo-dots--loading">
                        <div class="header-promo__dot header-promo__dot--loading"></div>
                        <div class="header-promo__dot header-promo__dot--loading"></div>
                        <div class="header-promo__dot header-promo__dot--loading"></div>
                    </div>
                </div>
                <h3>Бестселлеры</h3>
                <div v-if="booksFavorite.length !== 0" class="header-cards">
                    <div v-for="item in booksFavorite" :key="item.id" v-show="item.favorite === true" class="card">
                        <div class="card__img">
                            <img @click="$router.push('/b/'+item.slug)" :src="item.img_url" >
                        </div>
                        <div class="card__header">
                            <div class="card__price">{{ item.cost }} ₽</div>
                            <div v-show="item.cost_with_discount != null" class="card__info">
                                <div class="card__info-disc">-42%</div>
                                <div class="card__info-nosale">{{ item.cost_with_discount }} ₽</div>
                            </div>
                        </div>
                        <div class="card__name" v-if="item.title.length >= 200"> {{item.title.substr(0, 197)}}...</div>
                        <div class="card__name" v-else> {{item.title}}</div>
                        <div class="card__author">{{item.authors[0]}}</div>
                        <button @click="$store.dispatch('removeCart', item.id)" v-if="checkCart(item.id)" class="card__btn card__btn--active">В корзине</button>
                        <button @click="$store.dispatch('addCart', [item.id, 1])" v-else class="card__btn">В корзину</button>
                    </div>
                </div>
                <div v-else class="header-cards header-cards--loading">
                    <div class="card card--loading">
                        <div class="card__img"></div>
                        <div class="card__header">
                            <div class="card__price"></div>
                            <div class="card__info">
                                <div class="card__info-disc"></div>
                            </div>
                        </div>
                        <div class="card__name"></div>
                        <div class="card__author"></div>
                        <button class="card__btn"></button>
                    </div>
                    <div class="card card--loading">
                        <div class="card__img"></div>
                        <div class="card__header">
                            <div class="card__price"></div>
                            <div class="card__info">
                                <div class="card__info-disc"></div>
                            </div>
                        </div>
                        <div class="card__name"></div>
                        <div class="card__author"></div>
                        <button class="card__btn"></button>
                    </div>
                    <div class="card card--loading">
                        <div class="card__img"></div>
                        <div class="card__header">
                            <div class="card__price"></div>
                            <div class="card__info">
                                <div class="card__info-disc"></div>
                            </div>
                        </div>
                        <div class="card__name"></div>
                        <div class="card__author"></div>
                        <button class="card__btn"></button>
                    </div>
                    <div class="card card--loading">
                        <div class="card__img"></div>
                        <div class="card__header">
                            <div class="card__price"></div>
                            <div class="card__info">
                                <div class="card__info-disc"></div>
                            </div>
                        </div>
                        <div class="card__name"></div>
                        <div class="card__author"></div>
                        <button class="card__btn"></button>
                    </div>
                </div>
                <div v-if="this.$store.getters.getCategories.length !== 0" class="header-nav">
                    <div v-show="category.type === 'min'" v-for="item in typeCategoryList()" :key="'min'+item.id" @click="changeCategory(item.id)" :class="{'header-nav__item--active': category.currentId == item.id}" class="header-nav__item">
                        {{item.name}}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 480"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                    </div>
                    <div v-show="category.type === 'max'" v-for="item in this.$store.getters.getCategories" :key="item.id" @click="changeCategory(item.id)" :class="{'header-nav__item--active': category.currentId == item.id}" class="header-nav__item">
                        {{item.name}}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                    </div>
                    <div v-show="this.category.type === 'min'" v-if="this.$store.getters.getCategories.length >= 10" @click="toggleCategoryList"  class="header-nav__item">Еще</div>
                    <div v-show="this.category.type === 'max'" @click="toggleCategoryList" class="header-nav__item">Скрыть</div>
                </div>
                <div v-else class="header-nav header-nav--loading">
                    <div style="width: 100px;" class="header-nav__item header-nav__item--loading"></div>
                    <div style="width: 150px;" class="header-nav__item header-nav__item--loading"></div>
                    <div style="width: 60px;" class="header-nav__item header-nav__item--loading"></div>
                </div>
            </div>
        </header>
        <main>
            <div class="container">
                <section v-if="books.length !== 0" class="s-cards">
                    <div v-for="item in books" :key="item.slug"  class="card">
                        <div class="card__img">
                            <img @click="$router.push('/b/'+item.slug)" :src="item.img_url" >
                        </div>
                        <div class="card__header">
                            <div class="card__price">{{ item.cost }} ₽</div>
                            <div v-show="item.cost_with_discount != null" class="card__info">
<!--                                <div class="card__info-disc">-42%</div>-->
                                <div class="card__info-nosale">{{ item.cost_with_discount }} ₽</div>
                            </div>
                        </div>
                        <div class="card__name" v-if="item.title.length >= 200"> {{item.title.substr(0, 197)}}...</div>
                        <div class="card__name" v-else> {{item.title}}</div>
                        <div class="card__author">{{item.authors[0]}}</div>
                        <button @click="$store.dispatch('removeCart', item.id)" v-if="checkCart(item.id)" class="card__btn card__btn--active">В корзине</button>
                        <button style="font-size: 9px" v-else-if="item.in_stock !== true" class="card__btn card__btn--active">Нет в наличии</button>
                        <button @click="$store.dispatch('addCart', [item.id, 1])" v-else class="card__btn">В корзину</button>
                    </div>
                </section>
                <div class="s-cards__empty s-cards-empty" v-else-if="booksResult === false">
                    <span>Книг в выбранной категории нет</span>
                </div>
                <section v-else class="s-cards s-cards--loading">
                    <div class="card card--loading">
                        <div class="card__img"></div>
                        <div class="card__header">
                            <div class="card__price"></div>
                            <div class="card__info">
                                <div class="card__info-disc"></div>
                            </div>
                        </div>
                        <div class="card__name"></div>
                        <div class="card__author"></div>
                        <button class="card__btn"></button>
                    </div>
                    <div class="card card--loading">
                        <div class="card__img"></div>
                        <div class="card__header">
                            <div class="card__price"></div>
                            <div class="card__info">
                                <div class="card__info-disc"></div>
                            </div>
                        </div>
                        <div class="card__name"></div>
                        <div class="card__author"></div>
                        <button class="card__btn"></button>
                    </div>
                    <div class="card card--loading">
                        <div class="card__img"></div>
                        <div class="card__header">
                            <div class="card__price"></div>
                            <div class="card__info">
                                <div class="card__info-disc"></div>
                            </div>
                        </div>
                        <div class="card__name"></div>
                        <div class="card__author"></div>
                        <button class="card__btn"></button>
                    </div>
                </section>
                <div v-if="pageCounter.max !== 1 && pageCounter.max !== 0" class="pagination">
                    <button @click="pagePrev()" class="pagination-left">
                        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.34363 6.98486L6.98426 0.344238C7.44324 -0.114746 8.18543 -0.114746 8.63953 0.344238L9.74304 1.44775C10.202 1.90674 10.202 2.64893 9.74304 3.10303L5.0409 7.81494L9.74793 12.522C10.2069 12.981 10.2069 13.7231 9.74793 14.1772L8.64441 15.2856C8.18543 15.7446 7.44324 15.7446 6.98914 15.2856L0.348513 8.64502C-0.115354 8.18604 -0.115354 7.44385 0.34363 6.98486Z" fill="#878788"/>
                        </svg>
                    </button>
                    <div class="pagination__container">
                        <button v-show="pageCounter.current !== 1" @click="paginationPush(1)" class="pagination__first">1</button>
                        <button v-show="pageCounter.current !== 1 && pageCounter.current !== 1 + 1 && pageCounter.current !== 1 + 2" class="pagination__other">...</button>
                        <button v-show="pageCounter.current !== 1 && pageCounter.current !== 1 + 1" @click="paginationPush(pageCounter.current - 1 === 0 ? 1 : pageCounter.current - 1)" class="pagination__prev">{{ pageCounter.current - 1 === 0 ? 1 : pageCounter.current - 1}}</button>

                        <div class="pagination__current">{{ pageCounter.current }}</div>

                        <button v-show="pageCounter.current !== pageCounter.max && pageCounter.current !== pageCounter.max - 1" @click="paginationPush(pageCounter.current + 1 > pageCounter.max ? pageCounter.max : pageCounter.current + 1)" class="pagination__next">{{ pageCounter.current + 1 > pageCounter.max ? pageCounter.max : pageCounter.current + 1}}</button>
                        <button v-show="pageCounter.current !== pageCounter.max && pageCounter.current !== pageCounter.max - 1 && pageCounter.current !== pageCounter.max - 2" class="pagination__other">...</button>
                        <button v-show="pageCounter.current !== pageCounter.max" @click="paginationPush(pageCounter.max)" class="pagination__last">{{ pageCounter.max }}</button>
                    </div>
                    <button @click="pageNext()" class="pagination-right">
                        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.74365 8.64502L3.10303 15.2856C2.64404 15.7446 1.90186 15.7446 1.44775 15.2856L0.344238 14.1821C-0.114746 13.7231 -0.114746 12.981 0.344238 12.5269L5.05127 7.81982L0.344238 3.11279C-0.114746 2.65381 -0.114746 1.91162 0.344238 1.45752L1.44287 0.344238C1.90186 -0.114746 2.64404 -0.114746 3.09814 0.344238L9.73877 6.98486C10.2026 7.44385 10.2026 8.18604 9.74365 8.64502Z" fill="#878788"/>
                        </svg>
                    </button>
                </div>
                <div v-else class="pagination"></div>
            </div>
        </main>
    </div>

</template>

<script>
export default {
    name: 'mainPage',
    data: function() {
        return {
            cartCounter: 0,
            search: {
                status: false,
                query: '',
                data: [],
                request: false,
                static: true
            },
            books: [],
            booksResult: true,
            booksFavorite: [],
            category: {
                currentId: 0,
                type: 'min',
            },
            pageCounter: {
                current: 1,
                render: 15,
                max: 1
            },
            slider: {
                activeSlide: 0,
                slidesLength: 0,
            }

        }
    },
    methods: {
        paginationPush(i) {
            if(i === 1) {
                this.$router.push('/')
            } else {
                this.$router.push(`/?p=${i}`)
            }
            document.querySelector('.header-nav').scrollIntoView()
        },
        pageController() {
            if(this.$route.query.p < 0 || this.$route.query.p == null) {
                return 1
            } else if(this.$route.query.page > this.pageCounter.max) {
                return this.pageCounter.max
            } else{
                return Number(this.$route.query.p)
            }
        },
        pagePrev() {
            let page = this.pageCounter.current - 1
            if(page > 2) {
                this.$router.push(`/?p=${page}`)
            } else if(page === 1) {
                this.$router.push(`/`);
                document.querySelector('.header-nav').scrollIntoView()
            }
        },
        pageNext() {
            let page = this.pageCounter.current + 1
            page <= this.pageCounter.max ? this.$router.push(`/?p=${page}`) : false;
        },
        searchEnable() {
            this.search.status = true
            setTimeout(() => {
                document.querySelector('#search-bar').focus()
            }, 100)
        },
        searchDisable() {
            this.search.status = false
        },
        async searchSend() {
            if(this.search.query.length > 2 ) {
                let response = await fetch(this.$store.state.config.backend+'/books?search='+this.search.query, {
                    method: 'GET'
                })
                if(response.ok) {
                    let data = await response.json()
                    this.search.data = []
                    this.search.request = true;
                    if(data.results.length !== 0) {
                        this.search.request = false;
                        for(let i = 0; i < 10; i++) {
                            if (data.results[i] !== undefined) {
                                this.search.data.push(data.results[i])
                            }
                        }
                    }
                }
            } else {
                this.search.data = []
            }
        },
        typeCategoryList() {
            let arr = []
            let list = this.$store.getters.getCategories
            if(this.$store.getters.getCategories.length >= 5) {
                for(let i = 0; i < 10; i++) {
                  list[i] !== undefined ? arr.push(list[i]) : false;
                }
            } else {
                arr = this.$store.getters.getCategories
            }
            return arr
        },
        toggleCategoryList() {
          if(this.category.type === 'min') {
              this.category.type = 'max'
          } else {
              this.category.type = 'min'
          }
        },
        changeCategory(id) {
            this.category.currentId === id ? this.category.currentId = 0 : this.category.currentId = id
            // this.$route.meta.c === id ? this.$route.meta.c = 0 : this.$route.meta.c = id
            // console.log(this.$route.meta)
        },
        promoSlides() {
            this.slider.slidesLength = this.$store.getters.getSlides.length - 1;
            setInterval(() => {
                if(this.slider.activeSlide >= this.slider.slidesLength) {
                    this.slider.activeSlide = 0
                } else {
                    this.slider.activeSlide++
                }
            }, 2500)
        },
        checkCart(id) {
            let cart = this.$store.getters.getCart
            return !!cart.find(item => item.id == id);
        },
        async getFavorite() {
            let response = await fetch(this.$store.state.config.backend+'/books?favorite=true', {
                method: 'GET'
            })
            if(response.ok) {
                let data = await response.json()
                this.booksFavorite = data.results
            }
        },
        async getBooks() {
            let response = await fetch(this.$store.state.config.backend+'/books?per_page='+this.pageCounter.render+'&page='+(this.$route.query.p == null ? 1 : this.$route.query.p )+'&'+(this.category.currentId !== 0 ? 'category='+this.category.currentId : ''), {
                method: 'GET'
            })
            if(response.ok) {
                let data = await response.json()
                this.books = data.results
                this.pageCounter.max = Math.ceil(data.count / this.pageCounter.render)
                this.pageCounter.current = this.pageController()
                if(this.books.length === 0) {
                    this.booksResult = false
                } else {
                    this.booksResult = true
                }
            } else {
                await this.$router.push('/?p=1')
            }
        },
        getAllCart() {
            let data = 0
            if(this.$store.getters.getCart.length !== []) {
                for(let i = 0; i < this.$store.getters.getCart.length; i++) {
                    data = data + this.$store.getters.getCart[i].count
                }
            }
            return data
        }
    },
    mounted() {
        this.promoSlides()
        this.getFavorite()
        this.getBooks()
    },
    created() {
        document.title = `${this.$store.state.title}`
    },
    watch: {
        'category.currentId'() {
            this.getBooks()
        },
        '$route.query.p'() {
            this.getBooks()
        },
        'search.query'() {
            this.searchSend()
        }
    }
}
</script>


<style lang="scss" scoped src="../assets/style/main.scss">
</style>

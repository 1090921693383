<template>
    <div id="order-page">
        <div  @click="searchDisable()" :class="{'shadow--active': search.status === true}" class="shadow"></div>
        <nav class="nav" :class="{'nav--active': search.status === true}">
            <div class="nav-container">
                <router-link tag="div" to="/" class="nav-header">
                    <img src="../assets/img/logo.png" class="nav__logo">
                    <div class="nav__title">{{ $store.state.config.name }}</div>
                </router-link>
                <div class="nav-elem">
                    <button @click="searchEnable()" v-show="this.search.status !== true" class="nav__search">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.592 25.9369L22.2016 17.9982C21.9379 17.7346 21.9766 18.5982 21.6016 18.5982L21.7985 19.6856C23.4158 17.6174 24.3768 15.0161 24.3768 12.1863C24.3768 5.45455 18.9213 0 12.1884 0C5.45548 0 0 5.45455 0 12.1863C0 18.9181 5.45548 24.3726 12.1884 24.3726C15.0187 24.3726 17.6204 23.4118 19.6889 21.7947L19.2014 21.5979C19.2014 21.9729 18.3377 21.9342 18.6014 22.1978L25.9414 29.587C26.4922 30.1377 27.3829 30.1377 27.9278 29.587L29.5861 27.9289C30.137 27.3782 30.137 26.4876 29.592 25.9369ZM12.1884 19.6856C8.04551 19.6856 4.68784 16.3343 4.68784 12.1863C4.68784 8.04414 8.03965 4.68704 12.1884 4.68704C16.3313 4.68704 19.6889 8.03828 19.6889 12.1863C19.6889 16.3285 16.3371 19.6856 12.1884 19.6856Z" fill="#262626"/>
                        </svg>
                    </button>
                    <router-link tag="button" to="/order" class="nav__cart">
                        <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.9196 6.76348L32.6163 20.7469C32.2946 21.7427 31.45 22.4066 30.4446 22.4066H13.8348C12.8696 22.4066 11.9446 21.7842 11.6228 20.8714L5.26848 3.3195H1.6087C0.723913 3.3195 0 2.57261 0 1.65975C0 0.746888 0.723913 0 1.6087 0H6.39457C7.07826 0 7.68152 0.456431 7.92283 1.12033L14.4783 19.0871H29.7609L33.1793 7.88382H14.237C13.3522 7.88382 12.6283 7.13693 12.6283 6.22407C12.6283 5.3112 13.3522 4.56431 14.237 4.56431H35.3913C35.9141 4.56431 36.3967 4.85477 36.6783 5.26971C37 5.68465 37.0804 6.26556 36.9196 6.76348ZM14.6391 24.6058C13.9554 24.6058 13.2717 24.8963 12.7891 25.3942C12.3065 25.8921 12.025 26.5975 12.025 27.3029C12.025 28.0083 12.3065 28.7137 12.7891 29.2116C13.2717 29.7095 13.9554 30 14.6391 30C15.3228 30 16.0065 29.7095 16.4891 29.2116C16.9717 28.7137 17.2533 28.0083 17.2533 27.3029C17.2533 26.5975 16.9717 25.8921 16.4891 25.3942C16.0065 24.8963 15.3228 24.6058 14.6391 24.6058ZM29.0772 24.6058C28.3935 24.6058 27.7098 24.8963 27.2272 25.3942C26.7446 25.8921 26.463 26.5975 26.463 27.3029C26.463 28.0083 26.7446 28.7137 27.2272 29.2116C27.7098 29.7095 28.3935 30 29.0772 30C29.7609 30 30.4446 29.7095 30.9272 29.2116C31.4098 28.7137 31.6913 28.0083 31.6913 27.3029C31.6913 26.5975 31.4098 25.8921 30.9272 25.3942C30.4446 24.8963 29.7609 24.6058 29.0772 24.6058Z" fill="#262626"/>
                        </svg>
                        <div class="nav__cart--msg">{{getAllCart()}}</div>
                    </router-link>
                </div>
            </div>
            <div class="nav-search " :class="{'nav-search--active': search.status}">
                <div class="nav-search__bar">
                    <label for="search-bar">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6328 23.3432L19.9815 16.1984C19.7441 15.9611 19.7789 16.7384 19.4414 16.7384L19.6186 17.717C21.0742 15.8557 21.9391 13.5145 21.9391 10.9677C21.9391 4.90909 17.0292 0 10.9696 0C4.90993 0 0 4.90909 0 10.9677C0 17.0263 4.90993 21.9354 10.9696 21.9354C13.5168 21.9354 15.8584 21.0706 17.72 19.6153L17.2813 19.4381C17.2813 19.7756 16.5039 19.7408 16.7412 19.978L23.3472 26.6283C23.843 27.1239 24.6446 27.1239 25.135 26.6283L26.6275 25.136C27.1233 24.6404 27.1233 23.8389 26.6328 23.3432ZM10.9696 17.717C7.24096 17.717 4.21906 14.7009 4.21906 10.9677C4.21906 7.23972 7.23569 4.21834 10.9696 4.21834C14.6981 4.21834 17.72 7.23445 17.72 10.9677C17.72 14.6956 14.7034 17.717 10.9696 17.717Z" fill="#878788"/>
                        </svg>
                    </label>
                    <input autocomplete="off" v-model="search.query" id="search-bar" placeholder="Поиск..." type="text">
                    <button v-show="search.status === true" @click="searchDisable(); search.data = []; search.query = ''">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 35px; height: 35px;" viewBox="0 0 320 512"><path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" fill="#878788"/></svg>
                    </button>
                </div>
                <div v-show="search.request === true" class="nav-search__items" :class="{'nav-search__items--active': search.status === true}">
                    <h3 style="text-align: center">По вашему запросу ничего не найдено</h3>
                </div>
                <div v-if="search.data.length !== 0" :class="{'nav-search__items--active': search.status === true}" class="nav-search__items">
                    <div  v-for="item in search.data" :key="item.id" class="nav-search-items__item ns-item">
                        <div class="ns-item-info">
                            <img @click="$router.push('/b/'+item.slug)" :src="item.img_url" alt="" class="ns-item__img">
<!--                            <button @click="$store.dispatch('removeCart', item.id)" v-if="checkCart(item.id)"  class="ns-item__btn ns-item__btn&#45;&#45;active">В корзине</button>-->
<!--                            <button @click="$store.dispatch('addCart', [item.id, 1])" v-else  class="ns-item__btn">В корзину</button>-->
                        </div>
                        <div class="ns-item-content">
                            <div class="ns-item__name">{{ item.title }}</div>
                            <div class="ns-item__author">{{ item.authors[0] }}</div>
                            <div class="ns-item__price">
                                <!--                                <div v-show="item.cost_with_discount != null" class="ns-item__price&#45;&#45;promo">-42%</div>-->
                                <div class="ns-item__price--sale">{{ item.cost}} ₽</div>
                                <!--                                <div v-show="item.cost_with_discount != null" class="ns-item__price&#45;&#45;nosale">{{ item.cost_with_discount }} ₽</div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <main>

            <div class="container">
                <section v-if="books.length !== 0" class="s-items">
                    <itemComponent v-for="item in this.$store.getters.getCart"
                        :key="item.id"
                        :id="item.id"
                        :count="item.count"
                        @updateCost="updateCost"
                     />
                </section>
                <div class="empty" v-else>
                    <h2>Ваша корзина пуста</h2>
                    <router-link tag="button" to="/">
                        На главную
                    </router-link>
                </div>
                <section v-show="booksFavorite.length !== 0" class="s-cards">
                    <div class="s-cards__title">Также могут понравиться</div>
                    <div class="s-cards__container">
                        <div v-for="item in booksFavorite" :key="item.id" v-show="!checkCart(item.id) && item.favorite === true" class="sc-card">
                            <img @click="$router.push('/b/'+item.slug)" :src="item.img_url" class="sc-card__img">
                            <div class="sc-card__price">{{ item.cost }} ₽</div>
                            <button @click="$store.dispatch('removeCart', item.id)" v-if="checkCart(item.id)"  class="sc-card__btn">В корзине</button>
                            <button @click="$store.dispatch('addCart', [item.id, 1])" v-else  class="sc-card__btn">В корзину</button>
                        </div>
                    </div>
                </section>
                <section class="s-form">
                    <div class="s-form__toggler">
                        <button @click="booking.type = 'pickup'" v-bind:class="{'s-form__toggler-btn--active': booking.type === 'pickup'}" class="s-form__toggler-btn">Самовывоз</button>
                        <button @click="booking.type = 'delivery'" v-bind:class="{'s-form__toggler-btn--active': booking.type === 'delivery'}" class="s-form__toggler-btn">Доставка</button>
                    </div>
                    <div class="s-form__container">
                        <div v-show="booking.type === 'delivery'" class="s-form-container__title">Мы свяжемся с Вами чтобы подтвердить заказ</div>
                        <input v-model="booking.phone" @click="booking.phone === '' ? booking.phone = '+7' : null" v-mask="'+7 (###) ###-##-## #'" type="text" class="s-form__phone" placeholder="Телефон">
                        <div class="s-form-container__promo">
                            <input @focusout="checkPromo()" v-model="promo.code" placeholder="Промокод" maxlength="15" type="text">
                            <button  @click="delPromo()" v-show="promo.status === true" class="s-form-container__promo-del">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                            </button>
                            <div @click="checkPromo()" v-bind:class="{'s-form-container__promo-check--active': promo.status == true}" class="s-form-container__promo-check">
                                <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.13253 15.6858L0.307525 8.71255C-0.102508 8.29362 -0.102508 7.61436 0.307525 7.19538L1.79242 5.67821C2.20245 5.25923 2.86731 5.25923 3.27735 5.67821L7.875 10.3756L17.7227 0.314203C18.1327 -0.104734 18.7976 -0.104734 19.2076 0.314203L20.6925 1.83138C21.1025 2.25031 21.1025 2.92957 20.6925 3.34855L8.61746 15.6858C8.20739 16.1047 7.54257 16.1047 7.13253 15.6858Z" fill="white"/>
                                </svg>
                            </div>
                        </div>

                        <div v-show="booking.type === 'delivery'">
                            <div class="s-form__city">
                              <select v-model="booking.address.city" name="city">
                                <option disabled>Выберите город</option>
                                <option value="Махачкала">Махачкала</option>
                                <option value="Дербент">Дербент</option>
                                <option value="custom">Другой</option>
                              </select>
                              <input v-show="booking.address.city === 'custom'" type="text" placeholder="Город">
                            </div>
                            <div class="s-form-container__address">
                                <input v-model="booking.address.street" placeholder="Адрес" type="text" class="s-form-container__address-street">
                            </div>
                        </div>
                        <textarea v-model="booking.wishes" class="s-form__wishes" oninput="this.value.length > 23 ? this.style.height = 34 * Math.ceil(this.value.length / 22)+'px' : this.style.height = 37 +'px'"  maxlength="100" placeholder="Пожелания">
                        </textarea>
                        <div v-if="promo.status != false" class="s-form-container__sale">
                            <div>Скидка:</div>
                            <div>{{promo.cost}} ₽</div>
                        </div>
                        <div class="s-form-container__price">
                            <div>Итого:</div>
                            <div v-if="cost.discount != cost.original" class="s-form-container__price-total">{{cost.original }} ₽</div>
                            <div v-else-if="promo.cost != 0" class="s-form-container__price-total">{{cost.discount}} ₽</div>
                            <div class="s-form-container__price-sale">{{cost.discount - promo.cost}} ₽</div>
                        </div>
                        <button v-if="books.length !== 0" @click="sendOrder" class="s-form-container__btn">Оформить заказ</button>
                        <button v-else class="s-form-container__btn s-form-container__btn--disabled" disabled>Оформить заказ</button>
                    </div>

                </section>
            </div>
        </main>
    </div>
</template>

<script>
import itemComponent from '../components/OrderItem.vue'


export default {
    name: 'orderPage',
    components: {
        itemComponent
    },
    data: function() {
        return {
            search: {
                status: false,
                query: '',
                data: [],
                request: false
            },
            cost: {
                discount: 0,
                original: 0
            },
            booksFavorite: [],
            books: [],
            cart: [],
            booking: {
                type: 'pickup',
                address: {
                    city: 'Махачкала',
                    street: '',
                },
                phone: '',
                wishes: ''
            },
            promo: {
                status: false,
                code: '',
                cost: 0,
                data: {}
            }
        }
    },
    methods:  {
        searchEnable() {
            this.search.status = true
            setTimeout(() => {
                document.querySelector('#search-bar').focus()
            }, 100)
        },
        searchDisable() {
            this.search.status = false
        },
        async searchSend() {
            if(this.search.query.length > 2 ) {
                let response = await fetch(this.$store.state.config.backend+'/books?search='+this.search.query, {
                    method: 'GET'
                })
                if(response.ok) {
                    let data = await response.json()
                    this.search.data = []
                    this.search.request = true;
                    if(data.results.length !== 0) {
                        this.search.request = false;
                        for(let i = 0; i < 10; i++) {
                            if (data.results[i] !== undefined) {
                                this.search.data.push(data.results[i])
                            }
                        }
                    }
                }
            } else {
                this.search.data = []
            }
        },
        checkCart(id) {
            let cart = this.$store.getters.getCart
            return !!cart.find(item => item.id == id);
        },
        async getFavorite() {
            let response = await fetch(this.$store.state.config.backend+'/books?favorite=true', {
                method: 'GET'
            })
            if(response.ok) {
                let data = await response.json()
                this.booksFavorite = data.results
            }
        },
        delPromo() {
            this.promo.status = false
            this.promo.code = ''
            this.promo.cost = 0
            this.promo.data = {}
            document.querySelector('.s-form-container__promo>input').disabled = false;
        },
        promoCost() {
            let data = this.promo.data;
            if(this.promo.code !== '' && this.cost.discount !== 0 && this.cost.discount < data.min_sum) {
                this.$emit('notificationShow', 'warning', 'Ошибка', `Минимальная сумма заказа: ${data.min_sum}р`)
              document.querySelector('.s-form-container__promo>input').disabled = false;
            } else if(data.type === 1) {
                let cost = Math.floor((this.cost.discount / 100) * data.size)
                if(cost > data.max_discount !== null ? data.max_discount : 0) {
                    cost = data.max_discount
                }
                this.promo.cost = cost
                this.promo.status = true
            } else if(data.type === 2) {
                let cost = Math.floor((this.cost.discount / 100) * data.size)
                this.promo.cost = (this.cost.discount - cost)
                this.promo.status = true
            } else {
                this.promo.status = false
            }
        },
        async checkPromo() {
            if(this.promo.status !== 'active') {
                if(this.promo.code.length != 0) {
                    let response = await fetch(this.$store.state.config.backend+'/coupons/'+this.promo.code, {
                        method: 'GET'
                    })
                    if(response.ok) {
                        let data = await response.json()
                        this.promo.data = data
                        this.promo.code = data.code
                        document.querySelector('.s-form-container__promo>input').disabled = true;
                        this.promoCost()
                    } else {
                        let data = await response.json()
                        let msg
                        if(data.detail) {
                            msg = data.detail
                        } else if(data.code) {
                            msg = data.code[0]
                        } else {
                            msg = "Неизвестная ошибка"
                        }
                        this.$emit('notificationShow', 'warning', 'Ошибка', msg)
                    }
                } else {
                    this.$emit('notificationShow', 'warning', 'Ошибка', 'Вы не ввели купон')
                }
            }
        },
        async sendOrder() {
            let cart = this.$store.getters.getCart
            let token = this.$cookies.get('csrftoken')
            let clientid = this.$cookies.get('clientid')
            function books() {
                let items = ""
                cart.forEach(item => {
                    items += `"`+item.id+`"`+': '+ item.count +', '
                })
                items = '{'+items.slice(0, -2)+'}'
                return JSON.parse(items)
            }
            let phone = parseInt(this.booking.phone.slice(2).replace(/[^\d]/g, ''))
            if(this.booking.phone === '') {
                this.$emit('notificationShow', 'warning', 'Ошибка', 'Вы не ввели номер телефона')
            } else if(String(phone).length < 10) {
                this.$emit('notificationShow', 'warning', 'Ошибка', 'Неверный номер телефона')
            } else if(this.booking.address.city === 'custom' && document.querySelector('.s-form__city>input').value === '') {
                this.$emit('notificationShow', 'warning', 'Ошибка', 'Вы не указали город')
            } else if(this.booking.type === 'delivery' && this.booking.address.street === '') {
                this.$emit('notificationShow', 'warning', 'Ошибка', 'Вы не указали адрес')
            } else {
                let data
                if(this.booking.type === 'delivery') {
                    if(this.promo.code === '') {
                        data = {
                            "phone_number": phone,
                            "city": this.booking.address.city === 'custom' ? document.querySelector('.s-form__city>input').value : this.booking.address.city,
                            "delivery_type": this.booking.type.toUpperCase(),
                            "address": this.booking.address.street,
                            "books": books(),
                            "ym_id": clientid,
                            "wishes": this.booking.wishes
                        }
                    } else {
                        data = {
                            "phone_number": phone,
                            "city": this.booking.address.city === 'custom' ? document.querySelector('.s-form__city>input').value : this.booking.address.city,
                            "delivery_type": this.booking.type.toUpperCase(),
                            "address": this.booking.address.street,
                            "coupon_code": this.promo.code === '' ? null : this.promo.code,
                            "books": books(),
                            "ym_id": clientid,
                            "wishes": this.booking.wishes
                        }
                    }
                } else if(this.booking.type === 'pickup') {
                    if(this.promo.code === '') {
                        data = {
                            "phone_number": phone,
                            "delivery_type": this.booking.type.toUpperCase(),
                            "books": books(),
                            "ym_id": clientid,
                            "wishes": this.booking.wishes
                        }
                    } else {
                        data = {
                            "phone_number": phone,
                            "delivery_type": this.booking.type.toUpperCase(),
                            "coupon_code": this.promo.code,
                            "books": books(),
                            "ym_id": clientid,
                            "wishes": this.booking.wishes
                        }
                    }

                }
                let response = await fetch(this.$store.state.config.backend+'/orders', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': token
                    },
                    body: JSON.stringify(data)
                })
                if(response.status === 201) {
                    // let data = await response.json()
                    await this.$router.push('/order-after')
                    this.$store.state.cart = []
                    this.$cookies.set('cart', [])
                } else {
                    let data = await response.json()
                    console.log('error', data.data)
                    this.$emit('notificationShow', 'warning', 'Ошибка', 'Не удалось совершить заказ')
                }
            }
        },
        updateCost(cost, discount) {
            this.cost.discount += cost
            this.cost.original += discount
            this.promoCost()
        },
        loadBooks() {
            this.books = this.$store.getters.getCart
        },
        getAllCart() {
            let data = 0
            if(this.$store.getters.getCart.length !== []) {
                for(let i = 0; i < this.$store.getters.getCart.length; i++) {
                    data = data + this.$store.getters.getCart[i].count
                }
            }
            return data
        }
    },
    mounted() {
        this.getFavorite()
        this.loadBooks()
    },
    created() {
        document.title = `Корзина | ${this.$store.state.title}`
    },
    watch: {
        'search.query'() {
            this.searchSend()
        },
        '$store.state.cart'() {
            this.loadBooks()
        }
    }
}
</script>

<style lang="scss" src="../assets/style/order.scss"></style>

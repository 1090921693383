import Vue from 'vue'
import App from './App.vue'
import VueMask from 'v-mask'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
import Notification from "./components/notification";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false
Vue.use(Notification)
Vue.use(VueRouter)
Vue.use(VueMask);
Vue.use(VueCookies)

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')

<template>
    <div id="book-page">
        <div  @click="searchDisable()" :class="{'shadow--active': search.status === true}" class="shadow"></div>
        <nav class="nav" :class="{'nav--active': search.status === true}">
            <div class="nav-container">
                <router-link tag="div" to="/" class="nav-header">
                    <img src="../assets/img/logo.png" class="nav__logo">
                    <div class="nav__title">{{ $store.state.config.name }}</div>
                </router-link>
                <div class="nav-elem">
                    <button @click="searchEnable()" v-show="this.search.status !== true" class="nav__search">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.592 25.9369L22.2016 17.9982C21.9379 17.7346 21.9766 18.5982 21.6016 18.5982L21.7985 19.6856C23.4158 17.6174 24.3768 15.0161 24.3768 12.1863C24.3768 5.45455 18.9213 0 12.1884 0C5.45548 0 0 5.45455 0 12.1863C0 18.9181 5.45548 24.3726 12.1884 24.3726C15.0187 24.3726 17.6204 23.4118 19.6889 21.7947L19.2014 21.5979C19.2014 21.9729 18.3377 21.9342 18.6014 22.1978L25.9414 29.587C26.4922 30.1377 27.3829 30.1377 27.9278 29.587L29.5861 27.9289C30.137 27.3782 30.137 26.4876 29.592 25.9369ZM12.1884 19.6856C8.04551 19.6856 4.68784 16.3343 4.68784 12.1863C4.68784 8.04414 8.03965 4.68704 12.1884 4.68704C16.3313 4.68704 19.6889 8.03828 19.6889 12.1863C19.6889 16.3285 16.3371 19.6856 12.1884 19.6856Z" fill="#262626"/>
                        </svg>
                    </button>
                    <router-link tag="button" to="/order" class="nav__cart">
                        <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.9196 6.76348L32.6163 20.7469C32.2946 21.7427 31.45 22.4066 30.4446 22.4066H13.8348C12.8696 22.4066 11.9446 21.7842 11.6228 20.8714L5.26848 3.3195H1.6087C0.723913 3.3195 0 2.57261 0 1.65975C0 0.746888 0.723913 0 1.6087 0H6.39457C7.07826 0 7.68152 0.456431 7.92283 1.12033L14.4783 19.0871H29.7609L33.1793 7.88382H14.237C13.3522 7.88382 12.6283 7.13693 12.6283 6.22407C12.6283 5.3112 13.3522 4.56431 14.237 4.56431H35.3913C35.9141 4.56431 36.3967 4.85477 36.6783 5.26971C37 5.68465 37.0804 6.26556 36.9196 6.76348ZM14.6391 24.6058C13.9554 24.6058 13.2717 24.8963 12.7891 25.3942C12.3065 25.8921 12.025 26.5975 12.025 27.3029C12.025 28.0083 12.3065 28.7137 12.7891 29.2116C13.2717 29.7095 13.9554 30 14.6391 30C15.3228 30 16.0065 29.7095 16.4891 29.2116C16.9717 28.7137 17.2533 28.0083 17.2533 27.3029C17.2533 26.5975 16.9717 25.8921 16.4891 25.3942C16.0065 24.8963 15.3228 24.6058 14.6391 24.6058ZM29.0772 24.6058C28.3935 24.6058 27.7098 24.8963 27.2272 25.3942C26.7446 25.8921 26.463 26.5975 26.463 27.3029C26.463 28.0083 26.7446 28.7137 27.2272 29.2116C27.7098 29.7095 28.3935 30 29.0772 30C29.7609 30 30.4446 29.7095 30.9272 29.2116C31.4098 28.7137 31.6913 28.0083 31.6913 27.3029C31.6913 26.5975 31.4098 25.8921 30.9272 25.3942C30.4446 24.8963 29.7609 24.6058 29.0772 24.6058Z" fill="#262626"/>
                        </svg>
                        <div class="nav__cart--msg">{{getAllCart()}}</div>
                    </router-link>
                </div>
            </div>
            <div class="nav-search" :class="{'nav-search--active': search.status === true}">
                <div class="nav-search__bar">
                    <label for="search-bar">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6328 23.3432L19.9815 16.1984C19.7441 15.9611 19.7789 16.7384 19.4414 16.7384L19.6186 17.717C21.0742 15.8557 21.9391 13.5145 21.9391 10.9677C21.9391 4.90909 17.0292 0 10.9696 0C4.90993 0 0 4.90909 0 10.9677C0 17.0263 4.90993 21.9354 10.9696 21.9354C13.5168 21.9354 15.8584 21.0706 17.72 19.6153L17.2813 19.4381C17.2813 19.7756 16.5039 19.7408 16.7412 19.978L23.3472 26.6283C23.843 27.1239 24.6446 27.1239 25.135 26.6283L26.6275 25.136C27.1233 24.6404 27.1233 23.8389 26.6328 23.3432ZM10.9696 17.717C7.24096 17.717 4.21906 14.7009 4.21906 10.9677C4.21906 7.23972 7.23569 4.21834 10.9696 4.21834C14.6981 4.21834 17.72 7.23445 17.72 10.9677C17.72 14.6956 14.7034 17.717 10.9696 17.717Z" fill="#878788"/>
                        </svg>
                    </label>
                    <input autocomplete="off" v-model="search.query" id="search-bar" placeholder="Поиск..." type="text">
                    <button v-show="search.status === true" @click="searchDisable(); search.data = []; search.query = ''">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 35px; height: 35px;" viewBox="0 0 320 512"><path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" fill="#878788"/></svg>
                    </button>
                </div>
                <div v-show="search.request === true" class="nav-search__items" :class="{'nav-search__items--active': search.status === true}">
                    <h3 style="text-align: center">По вашему запросу ничего не найдено</h3>
                </div>
                <div v-if="search.data.length !== 0" :class="{'nav-search__items--active': search.status === true}" class="nav-search__items">
                    <div  v-for="item in search.data" :key="item.id" class="nav-search-items__item ns-item">
                        <div class="ns-item-info">
                            <img @click="$router.push('/b/'+item.slug); $router.go()" :src="item.img_url" alt="" class="ns-item__img">
<!--                            <button @click="$store.dispatch('removeCart', item.id)" v-if="checkCart(item.id)"  class="ns-item__btn ns-item__btn&#45;&#45;active">В корзине</button>-->
<!--                            <button @click="$store.dispatch('addCart', [item.id, 1])" v-else  class="ns-item__btn">В корзину</button>-->
                        </div>
                        <div class="ns-item-content">
                            <div class="ns-item__name">{{ item.title }}</div>
                            <div class="ns-item__author">{{ item.authors[0] }}</div>
                            <div class="ns-item__price">
                                <!--                                <div v-show="item.cost_with_discount != null" class="ns-item__price&#45;&#45;promo">-42%</div>-->
                                <div class="ns-item__price--sale">{{ item.cost}} ₽</div>
                                <!--                                <div v-show="item.cost_with_discount != null" class="ns-item__price&#45;&#45;nosale">{{ item.cost_with_discount }} ₽</div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <main>
            <div class="container">
                <section v-if="result === true" class="s-book">
                    <div class="s-book-header">
                        <div v-if="card.length !== 0 " class="s-book-header-info">
                            <div class="s-book-header-info__img">
                                <img :src="card.img_url">
                                <div v-if="card.length !== 0" class="s-book-header-category">
                                    <div v-for="item in getLabels()" :key="item.id" :style="{ background: item.bg_color, color: item.text_color }" class="s-book-header-category__item">{{item.name}}</div>
                                </div>
                                <div v-else class="s-book-header-category s-book-header-category--loading">
                                    <span style="width: 30%;"></span> <span style="width: 50%;"></span> <span style="width: 20%;"></span>
                                </div>
                            </div>
                            <div class="s-book-header-info__content">
                                <div class="s-book-header-info__name">{{card.title}}</div>
                                <div class="s-book-header-info__author">{{card.authors.join(', ')}}</div>
                                <div class="s-book-header-info__publisher">{{this.$store.getters.getPublishers.find(item => item.id == card.publisher_id) != null ? this.$store.getters.getPublishers.find(item => item.id == card.publisher_id).name : ''}}</div>
                                <div class="s-book-header-info__cover">{{ card.cover != null ? 'Обложка: '+card.cover : ''}}</div>
                                <div class="s-book-header-info__category">{{this.$store.getters.getCategories.find(item => item.id === card.category_id) != null ? this.$store.getters.getCategories.find(item => item.id === card.category_id).name : ''}}</div>
                                <div v-show="card.score !== null && card.score !== '0.0'" class="s-book-header-info__stars">
                                    <div v-for="item in rateCheck()" :key="item.id" :class="{
                                        's-book-header-info__stars-star--full': item == 'full',
                                        's-book-header-info__stars-star--half': item == 'half',
                                        's-book-header-info__stars-star--empty': item == 'full'
                                    }"
                                    class="s-book-header-info__stars-star">
                                        <svg v-show="item == 'full'" width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.58804 0.521791L5.51309 4.40022L0.870668 5.02416C0.0381459 5.13548 -0.295498 6.08165 0.308239 6.62358L3.66692 9.64078L2.87253 13.9029C2.72954 14.6734 3.60973 15.2504 4.34692 14.8901L8.5 12.8777L12.6531 14.8901C13.3903 15.2475 14.2705 14.6734 14.1275 13.9029L13.3331 9.64078L16.6918 6.62358C17.2955 6.08165 16.9619 5.13548 16.1293 5.02416L11.4869 4.40022L9.41196 0.521791C9.04019 -0.16953 7.96299 -0.178317 7.58804 0.521791Z" fill="#262626"/>
                                        </svg>
                                        <svg v-show="item == 'half'" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1806 5.02471L10.8114 4.40039L8.85884 0.521777C8.68332 0.175195 8.34093 0 7.99855 0C7.65825 0 7.31826 0.172852 7.14214 0.521777L5.18903 4.4001L0.819526 5.02383C0.0359533 5.13516 -0.278073 6.08115 0.28998 6.62314L3.45084 9.64072L2.70279 13.9028C2.59652 14.5116 3.09174 15 3.64636 15C3.79352 15 3.94486 14.9657 4.09024 14.8901L7.99915 12.878L11.9078 14.8907C12.0528 14.9654 12.2039 14.9994 12.3504 14.9994C12.9057 14.9994 13.4018 14.5128 13.2955 13.9037L12.5483 9.64131L15.7098 6.62432C16.2782 6.08232 15.9641 5.13603 15.1806 5.02471ZM11.5466 8.63408L11.0057 9.15029L11.1334 9.8792L11.7161 13.2029L8.6675 11.6329L7.99944 11.289L8.00034 1.99629L9.52361 5.02236L9.85733 5.68535L10.6039 5.79199L14.0137 6.2792L11.5466 8.63408Z" fill="#262626"/>
                                        </svg>
                                        <svg v-show="item == 'empty'" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.29482 5.14269L5.68253 5.08732L5.85866 4.73752L7.81141 0.859375C7.84652 0.789734 7.91023 0.749717 7.99871 0.750001C8.08904 0.750292 8.15469 0.792748 8.18894 0.860082C8.18901 0.860216 8.18908 0.860351 8.18915 0.860485L10.1413 4.73752L10.3175 5.08732L10.7052 5.14269L15.0745 5.76663L15.0751 5.76671C15.1665 5.77971 15.2184 5.83235 15.2402 5.89826C15.2516 5.93262 15.2524 5.96563 15.2461 5.99303C15.2405 6.01731 15.2273 6.04738 15.1923 6.08084L15.1921 6.08104L12.0309 9.09825L11.7407 9.37523L11.8101 9.77037L12.5576 14.032C12.5577 14.0321 12.5577 14.0323 12.5577 14.0324C12.5652 14.076 12.5586 14.1054 12.5486 14.1279C12.5371 14.1538 12.5153 14.1824 12.4813 14.2064C12.4123 14.2549 12.3295 14.2632 12.2522 14.2234L12.2521 14.2233L8.34331 12.2109L8 12.0341L7.65669 12.2109L3.74791 14.2233L3.74557 14.2245C3.66925 14.2642 3.58786 14.2563 3.51925 14.2078C3.4853 14.1839 3.46327 14.155 3.45158 14.1287C3.44135 14.1056 3.43475 14.0758 3.44235 14.0322C3.44236 14.0321 3.44236 14.0321 3.44237 14.032L4.18994 9.77037L4.25926 9.37523L3.96906 9.09825L0.807944 6.08104L0.807729 6.08084C0.772653 6.04738 0.759499 6.01731 0.753929 5.99303C0.747646 5.96563 0.748426 5.93262 0.759809 5.89826C0.781645 5.83235 0.833459 5.77971 0.924941 5.76671L0.925477 5.76663L5.29482 5.14269Z" stroke="black" stroke-width="1.5"/>
                                        </svg>

                                    </div>
                                </div>
                                <div v-if="card.cost_with_discount" class="s-book-header-info__price">
                                    <div  class="s-book-header-info__price-nosale">{{card.cost}} ₽</div>
                                    <div class="s-book-header-info__price-sale">{{card.cost_with_discount}} ₽</div>
                                </div>
                                <div v-else class="s-book-header-info__price">
                                    <div class="s-book-header-info__price-sale">{{card.cost}} ₽</div>
                                </div>
                                <div class="s-book-header-info__separator"></div>
                                <div v-if="checkCart(card.id)"  class="s-book-header-info__amount s-book-header-info-amount s-book-header-info-amount--active">
                                    <button @click=" $store.getters.getCart.find(i => i.id == card.id).count > 1 ? $store.dispatch('editCart', [card.id, $store.getters.getCart.find(i => i.id == card.id).count - 1]) :  $store.dispatch('removeCart', card.id)" class="s-book-header-info-amount__minus"></button>
                                    <div class="s-book-header-info-amount__current">{{ this.$store.getters.getCart.find(i => i.id == card.id).count }}</div>
                                    <button @click="$store.dispatch('editCart', [card.id, $store.getters.getCart.find(i => i.id == card.id).count + 1])" class="s-book-header-info-amount__plus"></button>
                                </div>
                                <button v-else-if="card.in_stock !== true" class="s-book-header-info__btn s-book-header-info__btn--active">Нет в наличии</button>
                                <button @click="$store.dispatch('addCart', [card.id, 1])" v-else class="s-book-header-info__btn">В корзину</button>
                            </div>
                        </div>

                        <div v-else class="s-book-header-info s-book-header-info--loading">
                            <div class="s-book-header-info__img"></div>
                            <div class="s-book-header-info__content">
                                <div class="s-book-header-info__name"></div>
                                <div class="s-book-header-info__author"></div>
                                <div class="s-book-header-info__category">
                                    <span style="width: 30%;"></span> <span style="width: 50%;"></span> <span style="width: 20%;"></span>
                                </div>
                                <div class="s-book-header-info__stars"></div>
                                <div class="s-book-header-info__price"></div>
                                <div class="s-book-header-info__separator"></div>
                                <button class="s-book-header-info__btn"></button>
                            </div>
                        </div>


                    </div>
                    <div v-if="card.length !== 0"  class="s-book-desc">
                        {{card.desc}}
                    </div>

                    <div v-else class="s-book-desc s-book-desc--loading">
                        <div>
                            <span style="width: 30%;"></span> <span style="width: 10%;"></span> <span style="width: 25%;"></span> <span style="width: 15%;"></span> <span style="width: 20%;"></span>
                        </div>
                        <div>
                            <span style="width: 30%;"></span> <span style="width: 25%;"></span> <span style="width: 15%;"></span> <span style="width: 10%;"></span> <span style="width: 20%;"></span>
                        </div>
                        <div>
                            <span style="width: 30%;"></span> <span style="width: 10%;"></span> <span style="width: 25%;"></span> <span style="width: 15%;"></span> <span style="width: 20%;"></span>
                        </div>
                        <div>
                            <span style="width: 30%;"></span> <span style="width: 25%;"></span> <span style="width: 15%;"></span> <span style="width: 10%;"></span> <span style="width: 20%;"></span>
                        </div>
                        <div>
                            <span style="width: 30%;"></span> <span style="width: 10%;"></span> <span style="width: 25%;"></span> <span style="width: 15%;"></span> <span style="width: 20%;"></span>
                        </div>
                        <div>
                            <span style="width: 30%;"></span> <span style="width: 25%;"></span> <span style="width: 15%;"></span> <span style="width: 10%;"></span> <span style="width: 20%;"></span>
                        </div>
                    </div>
                    <div v-if="card.length !== 0" class="s-book-footer">
                        <button @click="$router.push(`${prevRoute.path}${prevRoute.query.p !== undefined ? '?p='+prevRoute.query.p : ''}`)" class="s-book-footer__btn-back">
                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6461 19.5934L11.5561 20.661C11.0945 21.113 10.3482 21.113 9.89153 20.661L0.346167 11.3175C-0.115389 10.8655 -0.115389 10.1345 0.346167 9.68731L9.89153 0.33902C10.3531 -0.113007 11.0994 -0.113007 11.5561 0.33902L12.6461 1.40657C13.1126 1.86341 13.1028 2.60877 12.6265 3.05599L6.70974 8.57648H20.8216C21.4746 8.57648 22 9.09102 22 9.73059V11.2694C22 11.909 21.4746 12.4235 20.8216 12.4235H6.70974L12.6265 17.944C13.1077 18.3912 13.1175 19.1366 12.6461 19.5934Z" fill="white"/>
                            </svg>
                        </button>
                        <button @click="$router.push('/order')" v-if="checkCart(card.id)" class="s-book-footer__btn-cart s-book-footer__btn-cart--active">Перейти в корзину</button>
                        <button v-else-if="card.in_stock !== true" class="s-book-footer__btn-cart s-book-footer__btn-cart--active">Нет в наличии</button>
                        <button @click="$store.dispatch('addCart', [card.id, 1])" v-else class="s-book-footer__btn-cart">В корзину</button>
                    </div>
                    <div v-else class="s-book-footer">
                        <router-link tag="button" to="/" class="s-book-footer__btn-cart">
                            На главную
                        </router-link>
                    </div>
                </section>
                <section v-else class="s-book--error">
                    <h1>Книга не найдена</h1>
                    <router-link tag="button" to="/">
                        На главную
                    </router-link>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'book-page',
    data: function() {
        return {
            cartCounter: 0,
            search: {
                status: false,
                query: '',
                data: [],
                request: false
            },
            card: [],
            result: true,
        }
    },
    methods: {
        searchToggle() {
            this.search.status === true ? this.search.status = false : this.searchEnable()
        },
        searchEnable() {
            this.search.status = true
            setTimeout(() => {
                document.querySelector('#search-bar').focus()
            }, 100)
        },
        searchDisable() {
            this.search.status = false
        },
        async searchSend() {
            if(this.search.query.length > 2 ) {
                let response = await fetch(this.$store.state.config.backend+'/books?search='+this.search.query, {
                    method: 'GET'
                })
                if(response.ok) {
                    let data = await response.json()
                    this.search.data = []
                    this.search.request = true;
                    if(data.results.length !== 0) {
                        this.search.request = false;
                        for(let i = 0; i < 10; i++) {
                            if (data.results[i] !== undefined) {
                                this.search.data.push(data.results[i])
                            }
                        }
                    }
                }
            } else {
                this.search.data = []
            }
        },
        rateCheck() {
            let rate = this.card.score
            let stars = []
            for(let i = 0; i < 5; i++) {
                if(rate <= 0 ) {
                    stars.push('empty')
                    rate--
                }
                else if(Math.floor(rate) > 0) {
                    stars.push('full')
                    rate--
                }
                else if(rate % 1 != 0) {
                    stars.push('half')
                    rate = Math.floor(rate--)
                }
            }
            return stars
        },
        async loadBook() {
            let response = await fetch(this.$store.state.config.backend+'/books/'+this.$route.params.id, {
                method: 'GET'
            })
            if(response.ok) {
                let data = await response.json()
                this.card = data
                document.title = `${this.card.title} ${this.card.year !== null ? '['+this.card.year+']' : ''}`
            } else {
                this.result = false
            }
        },
        getLabels() {
            let cardLabels = this.card.labels;
            let labels = this.$store.getters.getLabels;
            let data = [];
            for(let i = 0; i < cardLabels.length; i++) {
                if(labels.find(item => item.id === cardLabels[i])) {
                    data.push(labels.find(item => item.id === cardLabels[i]))
                }

            }
            return data
        },
        checkCart(id) {
            let cart = this.$store.getters.getCart
            return !!cart.find(item => item.id == id);
        },
        getAllCart() {
            let data = 0
            if(this.$store.getters.getCart.length !== []) {
                for(let i = 0; i < this.$store.getters.getCart.length; i++) {
                    data = data + this.$store.getters.getCart[i].count
                }
            }
            return data
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    mounted() {
        this.rateCheck()
        this.loadBook()
    },
    watch: {
        'search.query'() {
            this.searchSend()
        }
    }
}
</script>

<style lang="scss" src="../assets/style/book.scss">
</style>


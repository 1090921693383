import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from "vue-cookies";
import AppConfig from '../config.json'

Vue.use(Vuex);
Vue.use(VueCookies)

function checkCookie() {
    try {
        let cart = Vue.$cookies.get('cart')
        if(cart != null) {
            return JSON.parse(cart)
        } else {
            Vue.$cookies.set('cart', '[]')
            return []
        }
    }
    catch (e) {
        Vue.$cookies.set('cart', '[]')
        return []
    }
}

export default new Vuex.Store({
    state: {
        slides: [],
        slidesStatus: true,
        categories: [],
        labels: [],
        publishers: [],
        cart: checkCookie(),
        title: document.title,
        config: AppConfig
    },
    mutations: {
        updateSlides(state, data) {
            state.slides = data
        },
        updateCategories(state, data) {
            state.categories = data
        },
        updateLabels(state, data) {
            state.labels = data
        },
        updatePublishers(state, data) {
            state.publishers = data
        },
        updateCart(state, data) {
            Vue.$cookies.set('cart', JSON.stringify(data))
            state.cart = data
        }
    },
    actions: {
        fetchSlides({commit}) {
            let attempt = 1
            let timer = setInterval(async () =>  {
                attempt >= 60 ? clearInterval(timer) : false;
                let response = await fetch(this.state.config.backend+'/slides/', {
                    method: "GET"
                })
                if(response.ok) {
                    let data = await response.json()
                    commit('updateSlides', data);
                    if(data.length === 0) {
                      this.state.slidesStatus = false
                    }
                    clearInterval(timer)
                } else {
                    attempt++
                }
            }, 1000)
        },
        fetchCategories({commit}) {
            let attempt = 1
            let timer = setInterval(async () =>  {
                attempt >= 60 ? clearInterval(timer) : false;
                let response = await fetch(this.state.config.backend+'/categories', {
                    method: "GET"
                })
                if(response.ok) {
                    let data = await response.json()
                    commit('updateCategories', data);
                    clearInterval(timer)
                } else {
                    attempt++
                }
            }, 1000)
        },
        fetchLabels({commit}) {
            let attempt = 1
            let timer = setInterval(async () =>  {
                attempt >= 60 ? clearInterval(timer) : false;
                let response = await fetch(this.state.config.backend+'/labels/', {
                    method: "GET"
                })
                if(response.ok) {
                    let data = await response.json()
                    commit('updateLabels', data);
                    clearInterval(timer)
                } else {
                    attempt++
                }
            }, 1000)

        },
        fetchPublishers({commit}) {
            let attempt = 1
            let timer = setInterval(async () =>  {
                attempt >= 60 ? clearInterval(timer) : false;
                let response = await fetch(this.state.config.backend+'/publishers', {
                    method: "GET"
                })
                if(response.ok) {
                    let data = await response.json()
                    commit('updatePublishers', data);
                    clearInterval(timer)
                } else {
                    attempt++
                }
            }, 1000)
        },
        addCart({commit}, book) {
            let books = this.getters.getCart
            books.push({id: book[0], count: book[1]})
            commit('updateCart', books)
        },
        editCart({commit}, book) {
            let books = this.getters.getCart
            let item = books.findIndex(item => item.id == book[0])
            books[item].id = book[0]
            books[item].count = book[1]
            commit('updateCart', books)
        },
        removeCart({commit}, book) {
            let books = this.getters.getCart
            let item = books.findIndex(item => item.id == book)
            books.splice(item, 1)
            commit('updateCart', books)
        }
    },
    getters: {
        getSlides(state) {
            return JSON.parse(JSON.stringify(state.slides))
        },
        getCategories(state) {
            return JSON.parse(JSON.stringify(state.categories))
        },
        getLabels(state) {
            return JSON.parse(JSON.stringify(state.labels))
        },
        getPublishers(state) {
            return JSON.parse(JSON.stringify(state.publishers))
        },
        getCart(state) {
            return JSON.parse(JSON.stringify(state.cart))
        }
    }
});
